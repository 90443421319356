<template>
  <v-container fluid style="width:960px">
    <h1 class="mt-0">Kreditoren &raquo; {{ vendor.name || "Neuer Kreditor" }}</h1>
    <v-card class="my-6">
      <v-card-text>
        <v-form ref="form" v-model="valid"
    lazy-validation>
    <v-row>
        <v-col cols="3">
    <v-text-field label="Name" v-model="vendor.name" :rules="[(v) => !!v || 'Dies ist ein Pflichtfeld']"></v-text-field>

        </v-col>
        <v-col cols="3">
    <v-text-field label="Kontonummer" v-model="vendor.account_number"></v-text-field>

        </v-col>
              <v-col cols="2">
    <v-select
     v-model="vendor.type"
     :items="types"
     label="Kostenart"
     :rules="[(v) => !!v || 'Dies ist ein Pflichtfeld']"
      required
     ></v-select>
    </v-col>
    <v-col cols="2">
       <v-checkbox
        v-model="vendor.vis"
        label="aktiv"
    ></v-checkbox>
    </v-col>
    </v-row>
        </v-form>
        </v-card-text>
    <v-card-actions>
         
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="cancel">{{ trl("cancel") }}</v-btn>
          <v-btn class="ml-2" color="primary" :disabled="!valid" @click="save">{{
            trl("save changes")
          }}</v-btn>
        </v-card-actions>
        </v-card>
  </v-container>
</template>

<script>
import { trl } from "@/utils/strings";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      valid: true,
      vendor: {},
      types: ["INT", "EXT"]
    };
  },
  computed: {
    ...mapGetters(["current", "vendors","divisions"]),
    scope(){
        let data = [];
        data.push({
            text: "in allen Units anzeigen",
            value: null,
        });

        this.divisions.forEach(d => data.push({text: d.name, value: d.id}))
        return data;
    }
  },
  methods: {
      trl,
      setVendor(id) {
          this.vendor = 
            this.vendors.find(item => item.id === Number(id)) || 
            {
                name: null, 
                type: null, 
                tenant_id: this.current.id,
                account_number: null, 
                vis:1
            };
      },
      cancel(){
         this.$router.replace('./');
      },
      async save(){

          if (!this.$refs.form.validate()) return;
         
          delete this.vendor.created_at;
          delete this.vendor.updated_at;
          delete this.vendor.deleted_at;

          this.vendor.vis = this.vendor.vis ? 1 : 0;
         
        let payload = {
            route: "vendor",
            data: this.vendor,
        };

         const res = await this.$store.dispatch("req", payload);

            await this.$store.dispatch("req", {
                route: "vendors",
                mutation: "vendors",
            });

         this.$router.replace("./");
      }
  },
  created() {
      this.setVendor(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.setVendor(to.params.id);
    next();
  },
};
</script>

<style>
</style>